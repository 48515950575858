// Fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'miwuki/custom';
@import 'miwuki/gracias';
