h1, h2, h3, h4, h5, h6 {
     font-weight: 700;
}

html, body {
    box-sizing: border-box;
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
}

.container {
    max-width: 960px;
    flex: 1;
}

.navbar {
    background-color: $miwuki !important;
}

.form-control:disabled, .form-control[readonly], .form-select:disabled, .form-select[readonly] {
    background-color: #e9ecef !important;
}

.banner, .form-select, .form-control {
    background-color: white !important;
}

footer{
    background-color: #272E43 !important;

    .logos {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 3rem;

        img {
            object-fit: contain;
            height: 50px;
        }
    }

    a {
        color: $miwuki !important;
        text-decoration: none;
    }
    a:hover {
        color: #ffffff !important;
        text-decoration: none;
    }
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited,
.btn-warning, .btn-warning:hover, .btn-warning:active, .btn-warning:visited {
    color: white !important;
}

.fa-circle-check {
    color: #9ad554;
}

.fa-circle-xmark {
    color: #ff583d;
}

.fa-circle-question {
    color: #f5a13f;
}


@media only screen and (max-width: 768px)
{
    footer{
        .logos {
            justify-content: start;
            flex-direction: column;
            gap: 1.5rem;
        }
    }
}
