body.gracias {
    & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        min-height: 100%;

        & > main {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        & > footer {
            width: 100%;
        }
    }
}
