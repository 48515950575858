// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$miwuki:  #4db8c0;
$naranja: #f8c960;
$primary: $miwuki;
$warning: $naranja;
